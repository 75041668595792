import React, { useEffect, useState } from "react";
import { useAuth } from '../../components/AuthContext/AuthContext'
import { sexualHealthFields, menstrualCycleFields } from '../../stories/Forms/fields';
import { shSchema, menstrualSchema } from '../../stories/Forms/validationSchema';
import { Row, Col, Container } from 'react-bootstrap';
import DynamicForm from '../../stories/Forms/DynamicForm';
import {
  assessmentStatusUrl,
  assessmentValidateUrl,
  phlebotomyAssessmentStatusUrl,
  assessementFinalSubmitUrl,
  phlebotomyAssessementFinalSubmit
} from "../../utils/constants";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import useApis from "../../services/useAPI";
import StepProgressBar from "../../stories/ProgressBars/ProgressBar";
import { EmojiSmile } from "react-bootstrap-icons";

let submissionData;

function SexualHealth() {
  const auth = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const { test_kit_id } = useParams();;
  const [isShTest, setIsShTest] = useState();
  const [isHormoneTest, setIsHormoneTest] = useState();
  const [data, setData] = useState({});
  const [allStepData, setAllStepData] = useState({});
  const [assessment, setAssessment] = useState({})
  const [is_assessment_completed, set_Is_assessment_completed] = useState(false);
  const { bid, test_type } = useParams();
  const formId = location.pathname.split('/').at(-1)
  const { setCompletedSteps, setIsCompleted, setStep } = auth;

  const isPhlebotomy = localStorage.getItem("is_phlebotomist");

  const { callApi, resultGet, resultPost } = useApis();

  const handleAssessmentSubmit = (values) => {
    // Remove keys with undefined values
    Object.keys(values).forEach((key) => {
      if (values[key] === undefined) {
        delete values[key];
      }
    });

    if (isShTest) {
      submissionData = {
        sexual_health: {
          sexually_active: values?.sexually_active || "",
          partners: values?.partners || "",
          recent_sex: values?.recent_sex || "",
          had_unprotected_sex: values?.had_unprotected_sex || "",
          unprotected_sex_type: values?.had_unprotected_sex === 'No' ? [] : values?.unprotected_sex_type || [],
          sti_diagnosed: values?.sti_diagnosed || "",
          sti_symptoms: values?.sti_symptoms || "",
          sti_symptoms_description:
            values?.sti_symptoms_description || "",
          potential_sti_exposure: values?.potential_sti_exposure || "",
          potential_sti_exposure_type:
            values?.potential_sti_exposure_type === 'No' ? [] : values?.potential_sti_exposure_type || [],
        },
        step: "sexual_health",
      };
    } else if (isHormoneTest) {
      submissionData = {
        menstrual: {
          cycle_length: values?.cycle_length,
          day_of_cycle: values?.day_of_cycle,
          contraception: values?.contraception,
        },
        step: "menstrual",
      };
    }
    if (submissionData) {
      callApi(
        {
          user_id: JSON.parse(localStorage.getItem("user_id")),
          ...submissionData
        },
        `${assessmentValidateUrl}`,
        "POST",
        true
      );
    }
    setData(submissionData)
    auth.setAllStepData((prevStep) => ({ ...prevStep, ...submissionData }));

    setIsCompleted(parseInt(formId) + 1); // Increment the step based on the latest value
    setCompletedSteps(prevSteps => prevSteps + 1); // Use functional update to access the latest value
    setStep(prevStep => prevStep + 1);
  }

  useEffect(() => {
    if (resultGet) {
      auth.setTotalSteps(resultGet?.list_of_steps?.length)
      if (isShTest) {
        submissionData = {
          sexual_health: {
            sexually_active: resultGet?.assessment_values?.sexual_health?.sexually_active || auth.allStepData?.sexual_health?.sexually_active || "",
            partners: resultGet?.assessment_values?.sexual_health?.partners || auth.allStepData?.sexual_health?.partners || "",
            recent_sex: resultGet?.assessment_values?.sexual_health?.recent_sex || auth.allStepData?.sexual_health?.recent_sex || "",
            had_unprotected_sex: resultGet?.assessment_values?.sexual_health?.had_unprotected_sex || auth.allStepData?.sexual_health?.had_unprotected_sex || "",
            unprotected_sex_type: resultGet?.assessment_values?.sexual_health?.unprotected_sex_type || auth.allStepData?.sexual_health?.unprotected_sex_type || [],
            sti_diagnosed: resultGet?.assessment_values?.sexual_health?.sti_diagnosed || auth.allStepData?.sexual_health?.sti_diagnosed || "",
            sti_symptoms: resultGet?.assessment_values?.sexual_health?.sti_symptoms || auth.allStepData?.sexual_health?.sti_symptoms || "",
            sti_symptoms_description:
              resultGet?.assessment_values?.sexual_health?.sti_symptoms_description || auth.allStepData?.sexual_health?.sti_symptoms_description || "",
            potential_sti_exposure: resultGet?.assessment_values?.sexual_health?.potential_sti_exposure || auth.allStepData?.sexual_health?.potential_sti_exposure || "",
            potential_sti_exposure_type:
              resultGet?.assessment_values?.sexual_health?.potential_sti_exposure_type || auth.allStepData?.sexual_health?.potential_sti_exposure_type || [],
          },
          step: "sexual_health",
        };
      } else if (isHormoneTest) {
        submissionData = {
          menstrual: {
            cycle_length: resultGet?.assessment_values?.menstrual?.cycle_length || auth.allStepData?.menstrual?.cycle_length,
            day_of_cycle: resultGet?.assessment_values?.menstrual?.day_of_cycle || auth.allStepData?.menstrual?.day_of_cycle,
            contraception: resultGet?.assessment_values?.menstrual?.contraception || auth.allStepData?.menstrual?.contraception,
          },
          step: "menstrual",
        };
      }
      setData(submissionData)
    }
  }, [resultGet])

  useEffect(() => {
    if (resultPost) {
      setIsCompleted(parseInt(formId)); // Increment the step based on the latest value
      setCompletedSteps(parseInt(formId) + 1); // Use functional update to access the latest value
      setStep(parseInt(formId));
      navigate(`/assessment/${test_kit_id}/${parseInt(formId) + 1}`, { state: { ...auth.allStepData } })
    }

    if (isPhlebotomy && test_type && bid) {
      callApi(null, phlebotomyAssessmentStatusUrl(bid), "GET");
    }
    else {
      test_kit_id &&
        localStorage.setItem("test_kit_id", location?.state?.test_kit_id);
      callApi(
        null,
        `${assessmentStatusUrl}${test_kit_id ||
        localStorage.getItem("test_kit_id")}`,
        "GET",
        true
      );
    }
  }, [auth.step, auth.completedSteps, auth.editReload, resultPost]);

  // Handles the final submission of all assessment data.
  const finalSubmit = () => {

    if (isPhlebotomy && test_type && bid) {
      callApi(auth.allStepData, phlebotomyAssessementFinalSubmit(bid), "POST");
      if (resultPost && test_type && bid) {
        auth?.showToast(
          "Assessment Completed",
          "All steps completed successfully",
          "../assets/mhc.png",
          <EmojiSmile />
        );
        navigate(`/bloods/boots/success-page/${test_type}/${bid}`);
      }
    }
    else {
      callApi(
        {
          ...auth.allStepData
        },
        `${assessementFinalSubmitUrl}/${test_kit_id ? test_kit_id : location?.state?.test_kit_id
        }`,
        "POST",
        true
      );
    }

    if (resultPost && !test_type && !bid) {
      navigate(`/assessment-details/${test_kit_id ? test_kit_id : location?.state?.test_kit_id}`);
    }
  };

  useEffect(() => {
    if (resultPost) {
      navigate(`/assessment-details/${test_kit_id}`);
    }
    if (resultGet?.test_kit_details?.test_kit_type?.includes('sex')) {
      setIsShTest(true)
      setIsHormoneTest(false)

    }
    else if (resultGet?.test_kit_details?.test_kit_type?.includes('menopause') || resultGet?.test_kit_details?.test_kit_type?.includes('polycystic')) {
      setIsHormoneTest(true)
      setIsShTest(false)

    }
  }, [resultGet]);


  return (
    <div className="row justify-content-center pt-4 start-assessment-page">
      <div className="col-10">
        <StepProgressBar
          steps={auth.totalSteps}
          currentStep={auth.step}
          completedSteps={auth.completedSteps}
          isCompleted={auth.isCompleted}
          is_assessment_completed={is_assessment_completed}
        />
        {(isShTest || isHormoneTest) && (
          <Container>
            <Row className="justify-content-center">
              <Col md={8}>
                <div className="mt-5">
                  <h1 className="mb-4">
                    {isShTest ? "Sexual health" : "Menstrual cycle"}
                  </h1>
                  <div className="assessment-form">
                    <DynamicForm
                      fields={
                        isShTest
                          ? sexualHealthFields
                          : isHormoneTest && menstrualCycleFields
                      }
                      validationSchema={isShTest ? shSchema : menstrualSchema}
                      blockRefresh={true}
                      modify={true}
                      initialValues={
                        isShTest
                          ? data?.sexual_health
                          : data?.menstrual
                      }
                      onSubmit={handleAssessmentSubmit}
                    />
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        )}
      </div>
    </div>
  );

}

export default SexualHealth
