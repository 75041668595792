import React, { useState, useEffect } from "react";
import logo from "../assets/images/mhc-logo-new.svg";
import Boots from "../assets/images/Vector.svg";
import { ReactComponent as booking } from "../assets/icons/link icon.svg";

import { ReactComponent as dashboard } from "../assets/icons/Dashboard.svg";
import { ReactComponent as results } from "../assets/icons/Results.svg";
import { ReactComponent as settings } from "../assets/icons/Settings.svg";
import { ReactComponent as assessment } from "../assets/icons/notepad.svg";
import { ReactComponent as support } from "../assets/icons/support.svg";
import { Nav, Navbar, Container, Image, ListGroup } from "react-bootstrap";
import Logout from "../views/Logout";
import { useAuth } from "../components/AuthContext/AuthContext";
import { useLocation } from "react-router-dom";
import { getTestTypeValueFromUrl } from "../utils";

const ListGroupItem = ({ to, active, title, icon }) => {
  const Icon = icon;

  const isExternalLink = to.startsWith("http") || to.startsWith("https");

  return (
    <ListGroup.Item
      as={isExternalLink ? "a" : Nav.Link}
      href={to}
      to={!isExternalLink ? to : undefined}
      active={active}
      target={isExternalLink ? "_blank" : undefined}
      rel={isExternalLink ? "noopener noreferrer" : undefined}
      className={"d-flex align-items-center gap-2"}
    >
      {icon ? <Icon width={25} height={25} className="" /> : null}
      <div className="text-light mt-1">{title}</div>
    </ListGroup.Item>
  );
};

const SideBar = () => {
  const [menu, setMenu] = useState([]);
  const auth = useAuth();
  const location = useLocation();
  const phlebotomy = localStorage.getItem("is_phlebotomist");

  // Effect to set menu items based on the phlebotomy flag
  useEffect(() => {
    if (
      auth.isAuthenticated ||
      JSON.parse(localStorage.getItem("isLoggedIn"))
    ) {
      if (phlebotomy === "true") {
        setMenu([
          { to: "/", name: "Dashboard", icon: dashboard },
          { to: "/activate", name: "Activate test", icon: assessment },
          {
            to: `/bloods/boots/${getTestTypeValueFromUrl(
              localStorage.getItem("test_type")
            )}`,
            name: "Boots Appointment",
            icon: booking
          },
          { to: "/tests", name: "My results", icon: results },
          { to: "/account", name: "Account", icon: settings },
          {
            to: "https://myhealthchecked.com/help",
            name: "Support",
            icon: support
          }
        ]);
      } else {
        setMenu([
          { to: "/", name: "Dashboard", icon: dashboard },
          { to: "/activate", name: "Activate test", icon: assessment },
          { to: "/tests", name: "My results", icon: results },
          { to: "/account", name: "Account", icon: settings },
          {
            to: "https://myhealthchecked.com/help",
            name: "Support",
            icon: support
          }
        ]);
      }
    } else {
      setMenu(null); // Clear the menu on logout
    }
  }, [
    auth,
    JSON.parse(localStorage?.getItem("isLoggedIn")),
    auth?.toast,
    phlebotomy
  ]);

  return (
    <div className="main-row m-0 row">
      <div className="sidebar-container sidebar-background p-0 col">
        <div className="sidebar-background p-0 m-0 overflow-hidden sticky-top col">
          <div className="row">
            <div className="col-12 col-md-12">
              <Navbar className="" expand="lg">
                <Container className="d-inline p-0 fs-1-2">
                  <Navbar.Brand>
                    <a href="/">
                      <Image
                        alt="My Health Checked"
                        style={{ backgroundColor: "#29384f" }}
                        className="d-block mhc-logo mt-1"
                        src={logo}
                      />
                    </a>
                    {phlebotomy === "true" && (
                      <a
                        href={`/bloods/boots/${getTestTypeValueFromUrl(
                          localStorage.getItem("test_type")
                        )}`}
                      >
                        <Image
                          alt="Boots"
                          height={80}
                          width={80}
                          className="d-block boots-logo"
                          src={Boots}
                        />
                      </a>
                    )}
                  </Navbar.Brand>
                  <Navbar.Toggle aria-controls="basic-navbar-nav" />
                  <Navbar.Collapse id="basic-navbar-nav ">
                    <Nav className="flex-column ms-1 mt-4">
                      {menu?.map((m, index) => (
                        <ListGroupItem
                          key={m.to}
                          to={m.to}
                          active={m.to === location.pathname}
                          icon={m.icon}
                          title={m.name}
                        />
                      ))}
                    </Nav>
                    {menu && <Logout />}
                  </Navbar.Collapse>
                </Container>
              </Navbar>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SideBar;
