import React from "react";
import { Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faExclamation,
  faHourglass
} from "@fortawesome/free-solid-svg-icons";
import PhoneConsultation from "../../assets/icons/Phone_Consultation_icon.svg";
import ConsultationFailed from "../../assets/icons/Phone_consultation_FAILv2.svg";

const TimelineItem = ({
  test,
  steps,
  hasFilledAssessment,
  canEditAssessment,
}) => {
  const {
    test_kit_status,
    bId,
    archive_reason :errorReason,
    id,
    assessment_id,
    unconfirmed_result,
    consultation_status,
    calendly_status,
    attempt_type,
    archive_reason,
    error_note,
    outcome
  } = test || {};

  let untestable = test?.untestable || "";

  return (
    <Container className="timeline mt-4">
      <Row>
        {steps.map((step, index) => {
          // If step has a function to check if it failed, run it
          let failed =
            step.failed?.(
              test_kit_status,
              errorReason,
              consultation_status,
              calendly_status,
              attempt_type,
              outcome,
              archive_reason
            );
          let current =
            step.inProgress?.(test_kit_status, errorReason, consultation_status);
          let complete = step.isCompleted?.(
            test_kit_status,
            errorReason,
            consultation_status
          );
          let label = step.label;

          // Don't show Consultation required step unless test is unconfirmed SH test
          let display = step.isVisible?.(unconfirmed_result);
          if (label === "Consultation Required" && !unconfirmed_result) {
            display = step.isVisible?.(unconfirmed_result);
          }

          // Show PhoneConsultation failed icon if the step fails
          if (
            step.label_before === "Consultation Required" ||
            step.label_before === "Consultation Failed"
          ) {
            if (unconfirmed_result && failed) {
              step.graphic = ConsultationFailed;
              step.label_before = "Consultation Failed";
            } else {
              step.graphic = PhoneConsultation;
              step.label_before = "Consultation Required";
            }
          }

          // Handle weird assessment step (it's outside of normal flow)
          // If has filled assessment, then step is complete
          // If hasn't, mark as current (so it's nicely highlighted)
          if (step.name === "assessment") {
            failed = !bId && !hasFilledAssessment && !assessment_id;
            current = !bId && !hasFilledAssessment;
            complete = !bId && hasFilledAssessment;

            // If assessment wasn't filled, change label to label_before
            if (!bId && failed && step.label_before) {
              label = step.label_before;
            }
            if (bId) {
              complete = true;
              label = step.label || "Assessment Completed";
            }
          }

          // To show status of timeline in red for untestable and inhibitory samples
          if (step.label === "Analysed at Lab" && error_note) {
            untestable = "error-untestable";
          }

          // If step wasn't completed and has "label_before", use it as a label
          // Used to show current state of step (like "Awaiting results")
          if (!complete && step.label_before) {
            label = step.label_before;
          }

          // If it's the current step and has a link, replace test ID in it
          let link =
            current && step.link?.replace("{test_id}", id)
              ? step.link?.replace("{test_id}", id)
              : null;

          if (
            (step.name === "assessment" && !canEditAssessment) ||
            test_kit_status === "error"
          ) {
            link = null;
          }

          const StepContainer = link ? Link : "div";

          const renderTimelineIcon = () => {
            if (failed) return <FontAwesomeIcon icon={faExclamation} />;
            if (current) return <FontAwesomeIcon icon={faHourglass} />;
            if (complete) return <FontAwesomeIcon icon={faCheck} />;
          };

          return (
            <StepContainer
              key={index}
              to={link}
              className={`timeline__step ${!display && 'timeline__step--display'} ${
                current && 'timeline__step--current'
              } timeline__step--${error_note && untestable} ${failed && 'timeline__step--failed'} ${
                complete && 'timeline__step--complete'
              }`}
            >
              <div className="timeline__img-container">
                <img src={step.graphic} alt={label} className="timeline__img" />
                <div className="timeline__icon">{renderTimelineIcon()}</div>
              </div>
              <p className="timeline__title">{label}</p>
            </StepContainer>
          );
        })}
      </Row>
    </Container>
  );
};

export default TimelineItem;
