import React, { useEffect, useState } from "react";
import { useAuth } from '../../components/AuthContext/AuthContext'
import { Row, Col, Container } from 'react-bootstrap';
import { Button } from '../../stories/Buttons/Button';
import {
    assessmentStatusUrl,
    phlebotomyAssessmentStatusUrl,
    assessementFinalSubmitUrl,
    phlebotomyAssessementFinalSubmit
} from "../../utils/constants";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import useApis from "../../services/useAPI";
import StepProgressBar from "../../stories/ProgressBars/ProgressBar";
import { EmojiSmile } from "react-bootstrap-icons";

function FinalStep() {
    const auth = useAuth();
    const navigate = useNavigate();
    const location = useLocation();
    const { test_kit_id, bid, test_type } = useParams();;
    const formId = location.pathname.split('/').at(-1)
    const { setCompletedSteps, setIsCompleted, setStep,
        handlePreviousStep,
        allStepData, } = auth;
    const [is_assessment_completed, set_Is_assessment_completed] = useState(false);
    const isPhlebotomy = localStorage.getItem("is_phlebotomist");
    const currentPath = window.location.pathname; // '/assessment/25/5'
    const parts = currentPath.split('/');
    const thisStep = parts[parts.length - 1]; // '5'
    localStorage.setItem("previous_step", thisStep)
    const { callApi, resultGet, resultPost } = useApis();

    useEffect(() => {
        if (isPhlebotomy && test_type && bid) {
            callApi(null, phlebotomyAssessmentStatusUrl(bid), "GET");
        }
        else {
            test_kit_id &&
                localStorage.setItem("test_kit_id", location?.state?.test_kit_id);
            callApi(
                null,
                `${assessmentStatusUrl}${test_kit_id ||
                localStorage.getItem("test_kit_id")}`,
                "GET",
                true
            );
        }
    }, [auth.step, auth.completedSteps, resultPost]);

    // Handles the final submission of all assessment data.
    const finalSubmit = () => {

        if (isPhlebotomy && test_type && bid) {
            callApi({ ...allStepData, ...resultGet?.assessment_values }, phlebotomyAssessementFinalSubmit(bid), "POST");
            if (resultPost && test_type && bid) {
                auth?.showToast(
                    "Assessment Completed",
                    "All steps completed successfully",
                    "../assets/mhc.png",
                    <EmojiSmile />
                );
                setIsCompleted(parseInt(formId)); // Increment the step based on the latest value
                setCompletedSteps(parseInt(formId) + 1); // Use functional update to access the latest value
                setStep(parseInt(formId));
                navigate(`/bloods/boots/success-page/${test_type}/${bid}`);
            }
        }
        else {
            callApi(
                allStepData,
                `${assessementFinalSubmitUrl}/${test_kit_id ? test_kit_id : location?.state?.test_kit_id
                }`,
                "POST",
                true
            );
        }
    };
    useEffect(() => {
        let mergedData;

        if (resultGet?.list_of_steps) {
            auth.setTotalSteps(resultGet?.list_of_steps?.includes('phlebotomist_booking_info') ? 6 : 7)
        }

        if (!resultGet?.assessment_values) return;

        mergedData = {
            medical_conditions: {
                condition: allStepData?.medical_conditions?.condition ?? resultGet?.assessment_values?.medical_conditions?.condition,
                other_description: allStepData?.medical_conditions?.other_description ?? resultGet?.assessment_values?.medical_conditions?.other_description,
            },
            medications: {
                medications: allStepData?.medications?.medications ?? resultGet?.assessment_values?.medications?.medications,
                other_description: allStepData?.medications?.other_description ?? resultGet?.assessment_values?.medications?.other_description,
            },
            body: {
                ...(allStepData?.body || resultGet?.assessment_values?.body),
            },
            smoking: {
                ...(allStepData?.smoking || resultGet?.assessment_values?.smoking),
            },
            alcohol: {
                ...(allStepData?.alcohol || resultGet?.assessment_values?.alcohol),
            },
            activity: {
                ...(allStepData?.activity || resultGet?.assessment_values?.activity),
            },
        };

        if (resultGet.test_kit_details?.test_kit_type.includes('sex')) {
            mergedData = {
                ...mergedData,
                sexual_health: {
                    ...(allStepData?.sexual_health || resultGet?.assessment_values?.sexual_health),
                },
            }
        } else if (resultGet.test_kit_details?.test_kit_type.includes('menopause') ||
            resultGet.test_kit_details?.test_kit_type.includes('polycystic')) {
            mergedData = {
                ...mergedData,
                menstrual: {
                    ...(allStepData?.menstrual || resultGet?.assessment_values?.menstrual),
                },
            }
        }

        auth.setAllStepData(mergedData);
    }, [resultGet, allStepData]);

    useEffect(() => {
        if (resultPost && !test_type && !bid) {
            auth?.showToast(
                "Assessment Completed",
                "All steps completed successfully",
                "../assets/mhc.png",
                <EmojiSmile />
            );
            navigate(`/assessment-details/${test_kit_id ? test_kit_id : location?.state?.test_kit_id}`);
        }
    }, [resultPost, resultGet, allStepData])

    useEffect(() => {
        set_Is_assessment_completed(resultGet?.is_assessment_completed)
    }, [resultGet])

    return (
        <div className="row justify-content-center pt-4 start-assessment-page">
            <div className="col-10">
                <StepProgressBar
                    steps={auth.totalSteps}
                    currentStep={auth.step}
                    completedSteps={auth.completedSteps}
                    isCompleted={auth.isCompleted}
                    is_assessment_completed={is_assessment_completed}
                />
                <Container>
                    <Row className="justify-content-center mt-4">
                        <Col md={8}>
                            <div className="assessment-form">
                                <h1>Assessment summary</h1>
                                <p>
                                    Our doctor will review your test results in the context of
                                    this Health Assessment. Please check that all details are
                                    correct before submitting, as you will not be able to edit
                                    your answers after submission.
                                </p>
                                <div className="d-flex justify-content-between align-items-center">
                                    <Button
                                        className="btn btn-gray float-start"
                                        variant="secondary"
                                        label="Previous step"
                                        onClick={() => handlePreviousStep(test_kit_id, resultGet?.test_kit_details?.test_kit_type)}
                                    />
                                    <Button
                                        className="btn btn-primary float-end"
                                        label="Assessment submit"
                                        onClick={() => finalSubmit()}
                                    />
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>

            </div>
        </div>
    )
}

export default FinalStep
