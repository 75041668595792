import React, { useEffect, useState } from "react";
import { useAuth } from '../../components/AuthContext/AuthContext'
import { medicationFields } from '../../stories/Forms/fields';
import { medicationsSchema } from '../../stories/Forms/validationSchema';
import { Row, Col, Container } from 'react-bootstrap';
import { useNavigate, useLocation, useParams } from "react-router-dom";
import useApis from "../../services/useAPI";
import StepProgressBar from "../../stories/ProgressBars/ProgressBar";
import {
  assessmentStatusUrl,
  assessmentValidateUrl,
  phlebotomyAssessmentStatusUrl,
} from "../../utils/constants";
import CheckboxGroupForm from "../../stories/Forms/CheckboxFormGroup";

let submissionData;

function Medications() {

  const auth = useAuth()
  const { bid, test_type } = useParams();
  const location = useLocation();
  const navigate = useNavigate()
  const currentPath = window.location.pathname; // '/assessment/25/5'
  const parts = currentPath.split('/');
  const thisStep = parts[parts.length - 1]; // '5'
  localStorage.setItem("previous_step", thisStep)
  const formId = location.pathname.split('/').at(-1)
  const [is_assessment_completed, set_Is_assessment_completed] = useState(false);
  const { setCompletedSteps, setIsCompleted, setStep, totalSteps,
    // step,
    completedSteps,
    isCompleted } = auth;
  const { test_kit_id } = useParams();

  const [data, setData] = useState({});

  const isPhlebotomy = localStorage.getItem("is_phlebotomist");

  const { callApi, resultGet, resultPost } = useApis();
  const handleAssessmentSubmit = (values) => {
    // Remove keys with undefined values
    Object.keys(values).forEach((key) => {
      if (values[key] === undefined) {
        delete values[key];
      }
    });

    const medications =
      values?.medications?.medications?.medications || [];
    const hasOtherMedications = medications?.includes("Other");
    const otherMedicationsDescription =
      values?.medications?.other_description || "";

    // Validate the 'Other' medications logic
    if (hasOtherMedications && !otherMedicationsDescription) {
      return;
    }

    submissionData = {
      medications: {
        medications: values.medications?.medications?.medication === 'Yes' ? medications : [], // List of medications
        other_description: hasOtherMedications ? otherMedicationsDescription : "", // Include description if 'Other' is selected
        details: hasOtherMedications ? otherMedicationsDescription : ""
      },
      step: "medications"
    };

    if (submissionData) {
      callApi(
        {
          user_id: JSON.parse(localStorage.getItem("user_id")),
          ...submissionData
        },
        `${assessmentValidateUrl}`,
        "POST",
        true
      );
    }
    setData(submissionData)
    auth.setAllStepData((prevStep) => ({ ...prevStep, ...submissionData }));

    setIsCompleted(parseInt(formId) + 1); // Increment the step based on the latest value
    setCompletedSteps(prevSteps => prevSteps + 1); // Use functional update to access the latest value
    setStep(prevStep => prevStep + 1);
  }

  useEffect(() => {
    if (resultGet) {

      auth.setTotalSteps(resultGet?.list_of_steps?.includes('phlebotomist_booking_info') ? 6 : 7)

      const medications =
        resultGet?.assessment_values?.medications?.medications || auth?.allStepData?.medications?.medications || [];
      const hasOtherMedications = medications?.includes("Other");
      const otherMedicationsDescription =
        resultGet?.assessment_values?.medications?.other_description || auth?.allStepData?.medications?.other_description || ""; // Fetch from the correct path

      // Validate the 'Other' medications logic
      if (hasOtherMedications && !otherMedicationsDescription) {
        console.error("Please provide details for 'Other' medications.");
        return; // Return early if validation fails
      }

      submissionData = {
        medications: {
          medications: medications, // List of medications
          other_description: hasOtherMedications ? otherMedicationsDescription : "", // Include description if 'Other' is selected
          details: hasOtherMedications ? otherMedicationsDescription : ""
        },
        step: "medications"
      };

      setData(submissionData)
    }
  }, [resultGet])

  useEffect(() => {

    if (resultPost) {
      setIsCompleted(parseInt(formId)); // Increment the step based on the latest value
      setCompletedSteps(parseInt(formId) + 1); // Use functional update to access the latest value
      setStep(parseInt(formId));
      navigate(`/assessment/${test_kit_id}/${resultGet?.test_kit_details?.test_kit_type?.includes('sex') || resultGet?.test_kit_details?.test_kit_type?.includes('menopause') || resultGet?.test_kit_details?.test_kit_type?.includes('polycystic') ? parseInt(formId) + 1 : 7}`, { state: { ...auth.allStepData } })
    }
    if (isPhlebotomy && test_type && bid) {
      callApi(null, phlebotomyAssessmentStatusUrl(bid), "GET");
    }
    else {
      test_kit_id &&
        localStorage.setItem("test_kit_id", location?.state?.test_kit_id);
      callApi(
        null,
        `${assessmentStatusUrl}${test_kit_id ||
        localStorage.getItem("test_kit_id")}`,
        "GET",
        true
      );
    }
  }, [auth.step, auth.completedSteps, auth.editReload, resultPost]);

  useEffect(() => {
    set_Is_assessment_completed(resultGet?.is_assessment_completed)
  }, [resultGet])

  return (
    <div className="row justify-content-center pt-4 start-assessment-page">
      <div className="col-10">
        <StepProgressBar
          steps={auth.totalSteps}
          currentStep={auth.step || parseInt(formId)}
          completedSteps={auth.completedSteps}
          isCompleted={auth.isCompleted}
          is_assessment_completed={is_assessment_completed}
        />
        <Container>
          <Row className="justify-content-center">
            <Col md={8}>
              <div className="mt-5">
                <h1 className="mb-4">Medication</h1>
                <CheckboxGroupForm
                  className="assessment-form"
                  initialValues={{ ...data?.medications }}
                  blockRefresh={true}
                  fields={medicationFields}
                  validationSchema={medicationsSchema}
                  is_assessment_completed={resultGet?.is_assessment_completed}
                  type={"medications"}
                  onSubmit={handleAssessmentSubmit}
                />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  )
}

export default Medications
